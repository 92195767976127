var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { backgroundColor, border, borderRadius, themeGet } from 'styled-system';
import { useOpenDisclaimer } from '../../../common/hooks/drawer';
import { useLocalization } from '../../providers/LocaleProvider';
import { Flex } from '../Flex';
import Icon from '../Icon';
import { CaptionRegularCR1 } from '../Typography';
var SponsoredButton = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    cursor: pointer;\n  }\n  &:active {\n    background-color: ", ";\n    color: ", ";\n  }\n  &:focus-visible {\n    outline: 2px solid ", ";\n    background-color: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    cursor: pointer;\n  }\n  &:active {\n    background-color: ", ";\n    color: ", ";\n  }\n  &:focus-visible {\n    outline: 2px solid ", ";\n    background-color: ", ";\n  }\n"])), backgroundColor, border, borderRadius, themeGet('colors.sys.neutral.background.default'), themeGet('colors.sys.neutral.text.active'), themeGet('colors.sys.secondary.border.focus'), themeGet('colors.sys.neutral.background.default'));
var sponsoredButtonStyle = {
    backgroundColor: 'sys.neutral.background.default',
    alignItems: 'center',
    width: 'fit-content',
    pl: 'sp_4',
    pr: 'sp_0',
    ml: '-4px',
    mb: 'sp_12',
    borderRadius: 'medium',
    as: 'button',
    border: 'none',
};
export var Sponsored = function (_a) {
    var className = _a.className, sellerName = _a.sellerName;
    var _b = useLocalization(), r = _b.r, t = _b.t;
    var handleIconClick = useOpenDisclaimer({
        title: t('h24_product_card_sponsored'),
        html: r.t('h24_product_card_sponsored_content', { sellerName: sellerName !== null && sellerName !== void 0 ? sellerName : '-' }),
    });
    return (React.createElement(SponsoredButton, __assign({}, sponsoredButtonStyle, { onClick: function (e) {
            e.preventDefault();
            handleIconClick();
        } }),
        React.createElement(CaptionRegularCR1, { className: className }, t('h24_product_card_sponsored')),
        React.createElement(Icon, { name: "info", size: 24 })));
};
var templateObject_1;
